












import { ReviewCategory } from '@/models/post-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop({ default: '' }) category!: ReviewCategory
  gotoListPostByCategory() {
    this.$router.push(`/community/category/${this.category.id}`)
  }
}
