import { render, staticRenderFns } from "./tag-category.vue?vue&type=template&id=fd3fb966&scoped=true&"
import script from "./tag-category.vue?vue&type=script&lang=ts&"
export * from "./tag-category.vue?vue&type=script&lang=ts&"
import style0 from "./tag-category.vue?vue&type=style&index=0&id=fd3fb966&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd3fb966",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
